.filterclass {
    padding-left: 15px;
    padding-right: 15px;
}

.mobiletoggle {
    position: sticky;
}

.product_banner {
    height: 75vh;
    /* margin-top: 60px; */
}

.product_banner {
    background-image: linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.7)),url(../../../../assests/img/cta.jpg);
    background-size: cover;
    margin-top: 30px;

}

.new_product_banner {
    background-image: linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.7)),url(../../../../assests/img/cta.jpg);
    background-size: cover;
    margin-top: 30px;
    height: 75vh;
}

@media (max-width:990px) {
    .product_banner{
        display: none !important;
    }


    .mobiletoggle {
        position: absolute;
        width: 80%;
        height: 100% !important;
        background-color: white;
        padding-left: 0 !important;
        padding-right: 0 !important;
        top: 80 !important;
    }
}

/* Pagiation css */

.page-item.active .page-link {
    z-index: 0;
    color: #fff;
    background-color: #0c0c0c;
    border-color: #000;
}

.page-link {
  position: relative;
  display: block;
  color: #000000;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.page-link:hover {
  z-index: 2;
  color: #000;
  background-color: #e9ecef;
  border-color: #dee2e6;
}


