.segmentbox{
    padding: 50px 30px;
    /* box-shadow: 0px 2px 15px rgb(247 33 33 / 10%); */
    box-shadow: -5px 2px 15px rgb(153 2 2 / 81%);
    transition: all ease-in-out 0.3s;
    height: 100%;
    border-radius: 13px;
}

.segmentbox:hover{
    padding: 30px 30px 70px 30px;
    box-shadow: 10px 15px 30px rgba(233, 6, 6, 0.418);
}

.segmentbox span{
    /* display: block; */
    font-size: 30px;
    font-weight: 700;
    color: #EE393E;
}

.segmentbox h5{
    font-size: 25px;
    font-weight: 600;
    padding: 0;
    margin: 10px 0;
    color: #6c665c;
}