.mybox p {
    font-size: 1rem;
}

.mybox {
    transition: all 0.3s ease;
    border-radius: 10px;
}

.mybox i {
    font-size: 3rem;
}

/* .mybox:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.mybox:hover i {
    color: #147EFB;
} */


.mybox:hover {
    box-shadow: rgba(241, 136, 136, 0.35) 0px 5px 15px;
}

.mybox:hover b {
    color: #EE393E;
}

.mybox img{
    height: 120px;
    width: 240px;
}

.box{
    box-shadow: rgba(241, 136, 136, 0.35) 0px 1px 6px;
    border-radius: 13px;
}

