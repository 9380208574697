.mainsection {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.mainloader-container {
  width: 80px;
  height: 4px;
  margin: 0 auto;
  background-color: #aaa;
  border-radius: 20px;
  overflow: hidden;
}

.mainloader {
  width: 60%;
  height: 100%;
  background-color: #de4b4b;
  display: block;
  animation: animate ease-out 1s infinite;
}

.mainloading {
  margin: 8px auto;
  width: 80px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #aaa;
}

@keyframes animate {
  0% {
    transform: translatex(-50%);
  }

  50% {
    transform: translatex(100%);
  }

  100% {
    transform: translatex(-50%)
  }

}

.mainsection img {
  opacity: 0;
  animation: fade 6s;
  transform: scale(1)
}

@keyframes fade {
  0% {
    opacity: 0;

  }

  100% {
    opacity: 1;
    /* transform:scale(1.2) */
  }
}