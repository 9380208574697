.franchise h1 {
    background-color: #fff;
    font-size: 40px;
    line-height: 50px;
    font-weight: 600;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.largeheading h1 {
    color: #000;
    font-size: 64px;
    line-height: 72px;
    font-weight: 800;
}

.newgym h1{
    color: #fff;
    font-size: 58px;
    line-height: 72px;
    font-weight: 800;
}

.newgym p{
    color: #fff;

    font-weight: 600;
    line-height: 30px;
}

.stats h1{
    color: #000;
    font-size: 60px;
    font-weight: 900;
}


.franchiseheadingtext{
    font-size: 70px;
    font-weight: bolder;
}

.franchiseparagraphtext{
    font-size: 22px;
    font-weight: 800;
}

.franchisebtn{
    background-color: rgba(91, 88, 79, .5);
    /* rgba(91, 88, 79, .5) */
    border-color: rgba(91, 88, 79, .5);
    color: #fff;
    border-radius: 20px;

}

.franchisebtn:hover{
    background-color:#000;
    color: #fff;

}

.profile-picture{
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 100px;
    /* margin-right: 15px; */
}

.react-multi-carousel-list button{
    z-index: 1 !important;
}