/* Popup style */
#popup_form .popup-box {
    position: fixed;
    /* background: #00000050; */
    background: #190202c9;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
}

#popup_form .box {
    position: relative;
    width: 25%;
    margin: 0 auto;
    height: auto;
    /* max-height: 70vh; */
    margin-top: calc(100vh - 85vh - 20px);
    /* background: #fff; */
    /* background:linear-gradient(to bottom right, #B05574, #F87E7B); */
    background: linear-gradient(to bottom right, #e06464, #EE393E);
    border-radius: 4px;
    padding: 0px;
    /* border: 1px solid #999; */
    /* overflow: auto; */
}

#popup_form .close-icon {
    content: 'x';
    cursor: pointer;
    /* position: fixed; */
    position: absolute;
    /* right: calc(20% - -15px);
    top: calc(100vh - 85vh - 29px); */
    top: -5px;
    right: -20px;

    /* background: #ededed; */
    color:#fff;
    background: #EE393E;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
}



@media only screen and (max-width: 600px) {

    #popup_form .box{
        width: 90%;
        /* margin: 0 auto; */
    }



    /* #popup_form .box {
        position: relative;
        width: 89%;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        margin-top: calc(100vh - 85vh - 20px);
        background: #fff;
        background: linear-gradient(to bottom right, #B05574, #F87E7B);
        background: linear-gradient(to bottom right, #e06464, #EE393E);
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #999;
        overflow: auto;
    } */



/* 
    #popup_form .close-icon {
        content: 'x';
        cursor: pointer;
        position: fixed;
        right: calc(4%);
        top: calc(100vh - 85vh - 29px);
        background: #ededed;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        line-height: 20px;
        text-align: center;
        border: 1px solid #999;
        font-size: 20px;
    } */
}

.bg-img-popup {
    width: 100%;
    height: 100%;
    background-size: cover;
    /* background-image: url(https://royalsportsnfitness.com/static/media/3.7615c2f93464e06bd78f.jpg); */
    background-image: url("../../../assests//img/rsfpopup.jpg");
}