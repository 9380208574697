.maincard{
    position: relative;
    /* width: 600px; */
    /* height: 350px; */
    /* background: #ccc; */
    border-radius: 20px;
    display: flex;
    transition: 0.5s;
}

.maincard .circle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.maincard .circle::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #d83133;
    clip-path: circle(120px at center);
    transition: 0.5s;
}


.maincard:hover .circle:before{
    background: #8d2323;
    clip-path: circle(400px at center);
    border-radius: 20px;
}

.maincard img{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 300px;
    pointer-events: none;
    transition: 0.5s;
}


.maincard:hover img{
    /* height: 500px; */
    height: 350px;
    left: 76%;
    width: 308px;
}

.maincard .content{
    position: relative;
    width: 50%;
    left: 20%;
    padding: 0px 20px 20px 20px;
    transition: 0.5s;
    opacity: 0;
    height: 250px;
    visibility: hidden;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.maincard:hover .content{
    left: 0;
    opacity: 1;
    visibility: visible;
   
}

.maincard .content h2{
    color: #fff;
    text-transform: uppercase;
    font-size: 1em;
     line-height: 1em;
}

.maincard .content p{
    color: #fff;
}

@media (max-width: 600px) {
    .maincard{
        width: auto;
        /* max-width: 350px; */
        align-items: flex-start;
        margin: 0px;

    }
    .maincard:hover{
        height: 500px;
    }
    .maincard .content{
        width: 100%;
        left: 0;
        padding: 40px;
        padding: 30px;
    }
    .maincard:hover img{
        top:70%;
        left:50%;
        height:300px;

    }
    
}

@media (max-width:420px) {
    .maincard .content{
       
        padding: 20px;
        margin: 4%;
    }
    .maincard:hover{
        /* height: 500px; */
        margin: 4%;
    }
}

