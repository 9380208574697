.inquiry-text {
    /* height: 55px; */
    margin-bottom: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 0;
    border-style: none none solid;
    border-width: 0 0 2px;
    border-color: #000 #000 #bdbdbd;
    background-color: transparent;
    font-size: 15px;
    line-height: 30px;
    font-weight: 300;
}

.form-control {
    /* border-style: none none solid;
    border-width: 0 0 2px;
    border-color: #000 #000 #bdbdbd; */
    /* background-color: transparent; */

    border-style: none;
    border-bottom: 2px solid black;
    border-radius: 0%;
}

.form-control:focus {
    border-color: #EE393E;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.contform {
    background-attachment: fixed;
    background-image: linear-gradient(rgba(175, 96, 51, .6), rgba(175, 96, 51, .7)), url('../../../assests/img/ach1.jpg');
    background-position: center;
    background-size: cover;
}