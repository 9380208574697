.clientslider {
  height: 220px;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.clientslider::before,
.clientslider::after {
  position: absolute;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  height: 100%;
  width: 25%;
  z-index: 2;
  pointer-events: none;
}

.clientslider::before {
  left: 0;
  top: 0;
}

.clientslider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}


.slide-track {
  width: calc(200px * 15);
  display: flex;
  animation: scroll 20s linear infinite;
  justify-content: space-between;
}


.clientslide {
  width: 150px;
  height: 112px;

  display: grid;
  place-items: center;
  transition: 0.5s;
  cursor: pointer;
}

.clientslide:hover {
  transform: scale(0.9)
}


@keyframes scroll {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(calc(-150px * 10));
  }
}