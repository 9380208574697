#whatsapp {
    bottom: 1rem;
    position: fixed;
    right: 1rem;
    background-color: green;
    padding: 9px 12px;
    border-radius: 50%;
    
}

#whatsapp i {
    font-size: 40px;
}

#callus {
    bottom: 5rem;
    position: fixed;
    right: 1rem;
    background-color: #000;
    padding: 16px 19px;
    border-radius: 50%;
    
}

#callus i {
    font-size: 25px;
}
