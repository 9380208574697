/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;1,300;1,400;1,500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

*{
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Montserrat', sans-serif;
}


html {
    overflow-x: hidden;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Montserrat', sans-serif;
}


body {
    font-family: 'Montserrat', sans-serif;
    color: #9fa1a4;
    line-height: 1.5;
}

a {
    color: rgb(255, 255, 255);
    text-decoration: none;
    transition: 0.3s all ease;
}

a:hover {
    color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
.font-heading {
    /* font-family: "Poppins", sans-serif; */
    font-family: 'Montserrat', sans-serif;


    color: #000;
}
/* 
.container {
    z-index: 2;
    position: relative;
} */

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
    background: #EE393E !important;
    border-color: #EE393E !important;
    /* border-radius: 20px; */
}


/* .btn .btn-primary {
    background: #EE393E !important ;
    border-color: #EE393E !important;
} */



.text-black {
    color: #000 !important;
}

.text-primary {
    color: #EE393E !important;
}

.border-top {
    border-top: 1px solid #f2f2f2 !important;
}

.border-bottom {
    border-bottom: 1px solid #f2f2f2 !important;
}

figure figcaption {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.8rem;
}

section {
    overflow: hidden;
}

.section {
    padding: 7rem 0;
}

.section-heading {
    font-size: 3rem;
    font-weight: 700;
    background: linear-gradient(-45deg, #3db3c5, #274685);
    /* -webkit-background-clip: text; */
    -webkit-text-fill-color: transparent;
}

#header {
    height: 80px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
}

#header.header-scrolled {
    background: rgba(0, 0, 0, 1);
    height: 60px;
}

#header.header-unscrolled {
    background: #EE393E;
    height: 60px;
}

#header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 4px 0;
    line-height: 1;
    font-weight: 500;
}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}


.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

/* .navbar li {
    position: relative;
} */

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.65);
    white-space: nowrap;
    transition: 0.3s;
    
}



.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
    color: #fff;
}



.navbar .dropdown ul li {
    min-width: 200px;
}

.navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    color: #101c36;
}

.navbar .dropdown ul a i {
    font-size: 12px;
}

#navbar ul a:hover,
#navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
    color: #2d71a1;
}

.navbar .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }

    .navbar .dropdown .dropdown:hover>ul {
        left: -100%;
    }
}


.mobile-nav-toggle {
    color: #fff;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }


    
.navbar-mobile {
    position: fixed;
    /* overflow: hidden; */
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    /* width: 100vw; */
    /* height: 100vh; */
    /* background: rgba(27, 49, 94, 0.9); */
    transition: 0.3s;
    z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
    
}

.navbar-mobile ul {
    /* display: block;
    position: absolute;
    top: 60px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s; */


    display: block;
    position: absolute;
    top: 60px;
    /* right: -8px; */
    /* bottom: -20px; */
    left: 0px;
    padding: 10px 0;
    /* border-radius: 10px; */
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);


}

.navbar-mobile a,
.navbar-mobile a:focus {
    /* padding: 10px 20px;
    font-size: 15px;
    color: #101c36; */
    display: flex;
    /* align-items: center; */
    justify-content: center;
    padding: 7px 0px;

}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
    color: #2d71a1;
}

.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: red;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
    min-width: 200px;
}

.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
    color: #2d71a1;
}

.navbar-mobile .dropdown>.dropdown-active {
    display: block;
}
}

.navbar a{
    color:"red" !important
}

.navbar-mobile ul{
    background-color: #000;
}








