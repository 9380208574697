@media only screen and (max-width: 768px) {
    .header_box {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #000;
        position: relative;
    }

    .header_box h1 {
        font-size: 2rem;
        font-weight: 300;
        font-family: 'Poppins', sans-serif;
    }

    .header_box a {
        list-style: none;
        text-decoration: none;
        color: #fff;
        font-size: 1rem;
        font-weight: 300;
        font-family: 'Poppins', sans-serif;
    }

    .nav_icon {
        width: 700px;
        display: flex;
        justify-content: space-around;
    }

    .header_box h1 {
        font-size: 1rem;
    }

    .header_box .nav_icon {
        display: flex;
        flex-direction: column;
        z-index: 99;
        background-color: #000;

    }

    .menu_btn {
        display: block !important;
        color: #fff !important;
    }

    .nav_icon {
        position: absolute;
        top: 80px;
        left: -784px;
        width: 100%;
        height: 88vh;
        background-color: rgba(0, 0, 0, 0.78);
        text-align: center;
        transition: all 0.7s ease;
    }

    .mobile {
        position: absolute;
        top: 60px;
        left: 0px;
        width: 100% !important;
        height: 100vh;
        padding: 50px 0px;
        /* background-image: url(../component/images/mininavbg.jpg); */
        /* background-image: url(../component/images/mininavbg2.jpg); */
        background-size: cover;
        z-index: 9999;
    }

    .mobile a {
        font-weight: 600;
        font-family: Georgia, 'Times New Roman', Times, serif;
        color: #222 !important;
    }

    .nav_icon a {
        color: #fff;
    }
}

.header_box {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    position: fixed;
    width: 100%;
    top: 2rem;
    z-index: 3;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

li {
    list-style: none;
}

.header_box h1 {
    font-size: 1.6rem;
    font-weight: 300;
    color: #F3742A;

}

.header_box a {
    list-style: none;
    text-decoration: none;
    color: #fff !important;
    font-size: 0.9rem;
    font-weight: bold;
}

.nav_icon {
    width: 743px;
    display: flex;
    justify-content: space-evenly;
}

.menu_btn {
    display: none;
}


/* .btn.btn-sm {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.btn.btn-primary {
    background: #E72E77;
    border-color: #E72E77;
}
 */



/* .headerimg {
  
        height: auto;
        max-height: 27% ;
    
} */

/* .video-play-btn {
    width: 90px;
    height: 90px;
    border: 2px solid #F3742A;
    position: absolute;
    content: "";
    border-radius: 50%;

} */


/* .small,
small {
    font-size: 80%;
    font-weight: 400;
} */

a {
    /* color: #ffffff; */
    text-decoration: none;
}








/* DropDown Css----- */

/* Navbar DropDown==== */

.navbar-nav .service-submenu a {
    color: #fff !important;
    font-size: 0.8em;
    padding: 0.3em 0 0.3em 0em;
    text-align: start;
    text-decoration: none;
}

.header_box .nav_icon li .service-submenu a:hover {
    color: #fff !important;

}

.header_box .nav_icon li .service-submenu a {
    color: #fff !important;

}

.service-submenu p {
    color: #fff;
}

.service-submenu h6 {
    color: #ffff;
    border-bottom: 2px solid #fff;
    padding: 0.5em 0;
    /* margin: 0 0.5em; */
    text-transform: uppercase;
    text-align: left;
    font-size: 1.2em;
}

.service-submenu {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: left;
    padding: 15px;
}

.service-submenu p {
    font-style: italic;
}

.service-menu {
    position: absolute;
    height: 0vh;
    top: 60px;
    left: 20%;
    transform: translateX(-20%);
    /* background: linear-gradient(rgba(2, 2, 2, 0), rgba(0, 0, 0, 0)), url(../component//images/drop2.jpg); */
    background-color: #000;
    background-position: bottom;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 0.7em;
    z-index: 1;
    /* justify-content: center; */
    transition: all 0.7s cubic-bezier(1, 0, 0, 1);
    visibility: hidden;
    opacity: 0;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.315);
}

.header_box .nav_icon li:hover .service-menu {
    visibility: visible;
    opacity: 1;
    height: 85vh;
}

.navactive {
    border-bottom: 2px solid #EE393E;
    padding: 4px;
}


/* Dropdown for equipment  */
.equipment-dropdown {
    position: relative;
    /* transition: all 1s ease-in; */
    
}

.dropdown-content {
    position: absolute;
    display: none;
    background-color: #f1f1f1;
    min-width: 130px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 3px 0px;
    /* transition: all 1s ease-in; */
    /* clip-path: polygon(0px 0px, 100% 0px, 100% 75%, 50% 99%, 0px 75%, 0% 50%); */
 
    

    /* position: absolute; */
    /* padding: 5px; */
    /* background-color: #111; */
    /* top: 70px; */
    /* left: 0;
    width: 100%; */
    /* border-radius: 4px; */
    /* display: none; */
    /* box-shadow: 0 14px 35px 0 rgba(9,9,12,0.4); */
    /* z-index: 2; */
    /* opacity: 0; */
    /* pointer-events: none; */
    /* transform: translateY(20px); */
    /* transition: all 200ms linear; */
}

.equipment-dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-content {
    /* opacity: 0; */
    /* max-height: 0; */
    /* overflow: hidden; */
    transition: all 0.5s ease-out;
  }

.dropdown-content a {
    display: block;
    color: black !important;
    padding: 5px 10px;
    text-decoration: none;
    white-space: nowrap;
}

.dropdown-content a:hover {
    background-color: #b1abab;
    border-left: 5px solid #EE393E;
    /* font-size: 16px; */
    /* border-radius: 5px; */
}

.mobiledropdown{
    position: relative;
    text-align: start;
    left: 46%;
}

#strip a:hover{
    color: #EE393E;
}

