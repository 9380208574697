.footer {
    /* background-image: url('../../../assests/img/footerbanner.jpg'); */
    background-image: linear-gradient(rgba(0,0,0,.5),rgba(0,0,0,.8)),url('../../../assests/img/footerbanner.jpg');
    /* background-position: 50%; */
    background-size: cover;
    color: #fff;
    font-size: 14px;
    /* padding: 30px 0; */
  }

  .footer h3 {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .footer ul li {
    margin-bottom: 10px;
  }
  .footer a {
    color: #fff;
  }
  .footer .copyright {
    margin-bottom: 0px;
  }
  .footer .copyright, .footer .credits {
    font-size: 14px;
  }

  
.social a {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin: 2px;
    border-radius: 50%;
    background: #f8f9fa;
    position: relative;
    text-align: center;
    transition: 0.3s background ease;
    color: #0d1e2d;
    line-height: 0;
  }
  .social a span {
    display: inline-block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .social a:hover {
    color: #fff;
    background: #ED3237;
  }
  .social a i {
    line-height: 0;
  }